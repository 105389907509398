
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import * as Api from "@/types/graphql";
import WithValidation from "@/utils/validation";
import gql from "graphql-tag";
import { Component, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: { Btn, Card },
  computed: {},
})
export default class HelpView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Mutation showSuccess;
  @Mutation showError;

  $refs!: {
    helpForm: WithValidation;
  };

  isFormValid = false;
  loading = false;

  subject = null;
  body = null;

  subjectRules = [(v) => !!v || "Inserire un oggetto"];
  bodyRules = [(v) => !!v || "Inserire un messaggio"];

  async sendEmail() {
    if (this.$refs.helpForm.validate()) {
      this.loading = true;
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation sendEmailToCNA($subject: String, $text: String) {
            sendEmailToCNA(subject: $subject, text: $text)
          }
        `,
        variables: {
          subject: this.subject,
          text: this.body,
        },
      });
      this.loading = false;
      if (res.data.sendEmailToCNA) {
        this.showSuccess("Email inviata correttamente");
        this.$refs.helpForm.reset()
      } else {
        this.showError("Errore invio email");
      }
    }
  }
}
